import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subscriber, from } from 'rxjs';
import { concatMap, delay, takeWhile } from 'rxjs/operators';
import { MutationService } from '../../fake-db/Mutation/mutation.service';
import { Apollo } from 'apollo-angular';
import { LoginSessionService } from '../../services/global/LoginSession.service';
import { EcommerceService } from '../../main/apps/e-commerce/service/e-commerce.service';
import { environment } from 'environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { SeguridadDatosService } from 'app/main/apps/dashboards/project/massive-message/seguridad-datos.service';
import { SweetAlertService } from 'app/services/global/sweet-alert.service';

@Injectable({
  providedIn: 'root'
})
export class MassiveMessageService {

  @Output() countMessageSent: EventEmitter<number> = new EventEmitter<number>();
  @Output() countMessageBad: EventEmitter<number> = new EventEmitter<number>();
  @Output() numberOfMessageToSend: EventEmitter<number> = new EventEmitter<number>();
  @Output() moveIcon: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCounter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCounterInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ShowCanceledInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processSuccessInvoiceCanceled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() totalSent: EventEmitter<number> = new EventEmitter<number>();
  @Output() EndSendInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() InvoiceSucess: EventEmitter<any> = new EventEmitter<any>();
  public numberMessagesSent: number = 0;
  private idEmpresa: string = '';
  public stopObservable: boolean = true;
  public restOfNumbersAndMessages: any[] = [];
  public urlImageExist: boolean = false;
  public urlImageToSend: string = '';
  private whatsapp: string = 'https://apissl.thomas-talk.me/Envio';
  private factura: string = 'https://facturadig.thomas-talk.me/Envio'   /* 'https://apitest2.thomas-talk.me/Envio' */  /* 'http://localhost:9007/Envio' */
  private akssl: string = environment.akssl;

  private AllDataInvoice: any = []
  RestOfInvoice: any = []
  countInvoiceSent: number = 0
  countInvoiceBad: number = 0
  MasiveInvoiceStop: boolean = false

  constructor(private mutationService: MutationService,
    private apollo: Apollo,
    private loginService: LoginSessionService,
    private eccomerceService: EcommerceService,
    private _httpClient: HttpClient,
    private security: SeguridadDatosService,
    private sweetAlertService: SweetAlertService

  ) {
    this.idEmpresa = this.loginService.GetDataUser().idEmpresa;
    this.showCounter.emit(false);
    this.showCounterInvoice.emit(false);
  }


  takeAllNumbers(allNumberAndMessage: any[], lic: string) {
    // Observable que toma todos los mensajes y emite de uno en uno
    // Y solo pasa al siguiente mensaje hasta que se envié el actual 
    this.restOfNumbersAndMessages = allNumberAndMessage;
    this.stopObservable = true;
    this.numberOfMessageToSend.emit(allNumberAndMessage.length);
    this.moveIcon.emit(true);
    this.processSuccessful.emit(false);
    return from(allNumberAndMessage).pipe(
      concatMap((contact) => this.sendOneMessage(contact, lic)),
      takeWhile(() => this.stopObservable)
    );
  }

  sendOneMessage(contact: any, lic: string): Observable<any> {
    // Procesa un único mensaje a la vez
    const { phone, message } = contact;
    return new Observable((subscriber: Subscriber<string>) => {
      let msgText = this.messageStructure(phone, message, lic);

      if (this.urlImageExist) {
        // Verifica si este mensaje tiene una imagen que debe ser enviada

        // Crea la estructura del mensaje que debe ser enviada
        let msgImage = this.messageStructure(phone, this.urlImageToSend, lic, '1');

        let sendToMsg = from([msgText, msgImage]);

        // Envia primero el texto de mensaje y luego la imagen  
        sendToMsg
          .subscribe((msg) => {
            this.mutationService.createChatCola(this.apollo, msg).then(res => {
              //res
            }).catch((err) => console.error(err))
          },
            (err) => console.error(err),
            () => {
              this.restOfNumbersAndMessages = this.restOfNumbersAndMessages.filter((contact_) => contact_ !== contact);
              this.SendWaNotif(message, lic, phone, this.urlImageToSend);
              this.numberMessagesSent += 1;
              this.countMessageSent.emit(this.numberMessagesSent);
              subscriber.next('Mensaje enviado');
              subscriber.complete();
            });
        return;
      }

      let sendToMsg = from([msgText]);
      sendToMsg.subscribe(() => {
        this.mutationService.createChatCola(this.apollo, msgText).then(res => {
          //Estoy dentro de createChatCola
        })
          .catch((err) => console.error("Error con createChatCola", err));
      }, (err) => { console.error("Error", err) },
        () => {
          this.restOfNumbersAndMessages = this.restOfNumbersAndMessages.filter((contact_) => contact_ !== contact);
          this.SendWaNotif(message, lic, phone);
          this.numberMessagesSent += 1;
          this.countMessageSent.emit(this.numberMessagesSent);
          subscriber.next('Mensaje enviado');
          subscriber.complete();

        });
    }).pipe(delay(1000));
  }

  messageStructure(phone: string, message: string, lic: string, contentType: string = '0') {
    const date = new Date();
    lic = lic.toString();
    lic = lic.includes('@c.us') ? lic : `${lic}@c.us`;
    phone = phone.toString();
    phone = phone.includes('@c.us') ? phone : `${phone}@c.us`;
    phone = phone.includes('58') ? phone : `58${phone}`;
    return {
      operador: "-1",   // Solo para pruebas
      idEmpresa: this.idEmpresa,
      phone: phone,
      idConversacion: `${lic.replace('@c.us', '')}_${phone.replace('@c.us', '')}_${date.getFullYear()}_${date.getMonth() + 1}-${date.getDate()}`,
      idmessage: `${phone.replace('@c.us', '')}_true_${this.eccomerceService.GenerateUniqueID()}`,
      me_to: "o",
      contentType: `${contentType}`,
      content: message,
      TimeRead: new Date().toISOString(),
      timestamp: Math.floor(date.getTime() / 1000.0),
      status: 0,
      statusc: 0,
      readmsg: false,
      lic: `${lic}`
    };
  }

  //Prueba de enviar mensajes masivos
  SendWaNotif(Content: string, lic: string, Number: string, Image?: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        let Phone = Number
        const DataWa = {
          "lic": lic,
          "Mensaje": Content,
          "Phone": Phone,
          "Archivos": [
            {
              "filename": "promo-image",
              "path": Image
            }
          ]
        }
        const headersData = {
          method: `SendWhats`,
          token: this.akssl,
          platform: "whatsapp",
        };
        this.MasterGETPOST(headersData, this.whatsapp, true, DataWa).then((data: any) => {
          //Esto lo agregue para probar
          resolve(data);
        }).catch((error: any) => {
          reject(error)
          console.error(error);
        })

        if (Phone) {
          resolve(true)
        }

      } catch (error) {
        reject(error)
      }
    })
  }

  MasterGETPOST(headersData: any, url: string, post?: boolean, body?: any) {
    return new Promise(async (resolve: any, reject: any) => {
      if (post) {
        this.security.EncrypDataHash(headersData).then((headers: any) => {
          this._httpClient.post(url, body, { headers }).subscribe((res: any) => {
            let jsonres;
            try {
              if (this.isJsonString(res)) {
                jsonres = JSON.parse(res)
              } else {
                jsonres = res
              }
              resolve(jsonres);
            } catch (error) {
              console.error(error)
            }
          })
        }).catch((error: any) => {
          reject(error)
        })
      }
      else {
        this.security.EncrypDataHash(headersData).then((headers: any) => {
          this._httpClient.get(url, { headers }).subscribe((res: any) => {
            let jsonres;
            try {
              if (this.isJsonString(res)) {
                jsonres = JSON.parse(res)
              } else {
                jsonres = res
              }
              resolve(jsonres);
            } catch (error) {
              console.error(error)
            }
          })
        }).catch((error: any) => {
          reject(error)
        })
      }

    })
  }

  isJsonString(jsonToParse: any) {
    try {
      JSON.parse(jsonToParse);
    } catch (e) {
      return false;
    }
    return true;
  }


  ReceiberFacturas(Data: any) {
    try {

      if (Data && Data.length > 0) {
        this.RestOfInvoice = Data
        this.AllDataInvoice = Data
        this.stopObservable = true;
        this.numberOfMessageToSend.emit(Data.length);
        this.moveIcon.emit(true);
        this.processSuccessInvoice.emit(false);
        this.Proccess_Send_Invoice()
      }
    } catch (error) {
      console.error(error)
    }
  }

  Proccess_Send_Invoice() {
    try {
      if (this.AllDataInvoice && this.AllDataInvoice.length > 0 && this.MasiveInvoiceStop === false) {

        /* const LimitInvoice = 10
        const DataInvoiceSend = this.AllDataInvoice.slice(0, LimitInvoice)
        //voy hacer el envio de las facturas
        this.SendInvoice(DataInvoiceSend).then((Res: any) => {

          if (Res && Res.status === true && Res.Message) {
            //ocurrio un error y muestro el mensaje de error en una alerta
            this.sweetAlertService.ShowError(Res.Message)
            this.InvoiceSucess.emit({
              data: {
                status: true,
                Data: [],
                ErrorData: DataInvoiceSend
              }
            });
            this.countInvoiceBad += DataInvoiceSend.length
            this.countMessageBad.emit(this.countInvoiceBad)
          } else if (Res && Res.status === true) {
            //recibo la repuesta de ue todo esta bien 
            this.countInvoiceSent += Res.Data.length
            this.countMessageSent.emit(this.countInvoiceSent);
            this.countInvoiceBad += Res.ErrorData.length
            this.countMessageBad.emit(this.countInvoiceBad)
            this.InvoiceSucess.emit({ data: Res });
          } else {
            //no se lograron generar las facturas
            this.countInvoiceBad += Res.ErrorData.length
          }

          this.AllDataInvoice = this.AllDataInvoice.slice(LimitInvoice, this.AllDataInvoice.length)
          if (this.MasiveInvoiceStop === false) {
            this.Proccess_Send_Invoice()
          } else {
            console.log("Envio detenido")
            this.moveIcon.emit(false)
          }

        }).catch((err: any) => console.error(err)) */

        this.SendInvoice(this.AllDataInvoice[0]).then((Res: any) => {
          console.log("Tengo la respuesta de la factura")
          console.log(Res)
          if (Res.status === true) {
            let Factura = "Sin URL"// Res.Data[0].UrlFact.replace(/[^0-9]+/g, "");
            this.AllDataInvoice[0].FACTURA = Res.Data[0].FACTURA;
            this.AllDataInvoice[0].URLFACTURA = Res.Data[0].UrlFact;
            this.RestOfInvoice = this.RestOfInvoice.filter((invoice: any) => invoice.CODIGO != this.AllDataInvoice[0].CODIGO)
            this.countInvoiceSent += 1
            this.countMessageSent.emit(this.countInvoiceSent);
            this.InvoiceSucess.emit(
              {
                data: {
                  status: true,
                  Data: [this.AllDataInvoice[0]],
                  ErrorData: []
                }
              });
          } else {
            this.countInvoiceBad += 1
            this.countMessageBad.emit(this.countInvoiceBad)
            this.InvoiceSucess.emit(
              {
                data: {
                  status: false,
                  Data: [],
                  ErrorData: [this.AllDataInvoice[0]]
                }
              });
          }
          this.AllDataInvoice.shift()
          if (this.MasiveInvoiceStop === false) {
            setTimeout(() => {
              this.Proccess_Send_Invoice()
            }, 1000);
          } else {
            console.log("Envio detenido")
            this.moveIcon.emit(false)
          }
        }).catch((err: any) => console.error(err))

      } else if (this.MasiveInvoiceStop === true) {
        console.log("Envio detenido")
        this.moveIcon.emit(false)
      } else {
        //this.moveIcon.emit(false)
        this.showCounterInvoice.emit(false);
        //this.EndSendInvoice.emit(true)
        this.countMessageSent.emit(this.countInvoiceSent)
        this.countMessageBad.emit(this.countInvoiceBad)
        this.countInvoiceSent = 0
        this.countInvoiceBad = 0
        this.processSuccessInvoice.emit(true);
        setTimeout(() => {
          //this.processSuccessInvoice.emit(false);
          //this.EndSendInvoice.emit(true)
        }, 10000);
      }

    } catch (error) {
      console.error(error)
    }
  }

  SendInvoice(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headersData = {
          //method: `RegisterDocumentByLote`,
          method: `GeneratedInvoice`,
          token: this.akssl,
          platform: "ThomasV11",
          //facturacion: "Lote"
          facturacion: "Normal"
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  AnularInvoice(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headersData = {
          method: `AnularFactura`,
          token: this.akssl,
          platform: "ThomasV11",
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  GetInvoice(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headersData = {
          method: `ConsultarDocumento`,
          token: this.akssl,
          platform: "ThomasV11",
        }
        this.MasterGETPOST(headersData, this.factura, true, Datos).then((ResData: any) => {
          resolve(ResData)
        }).catch((err: any) => {
          console.error(err)
          reject(err)
        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

}
