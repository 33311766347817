import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Observable, Subject, Subscriber } from 'rxjs';
import { ProjectDashboardDb } from 'app/fake-db/dashboard-project';
import { AnalyticsDashboardDb } from 'app/fake-db/dashboard-analytics';
import { CalendarFakeDb } from 'app/fake-db/calendar';
import { ClientesFakeDb } from 'app/fake-db/clientes';
import { TipoPagoDB } from 'app/fake-db/typopago';

import { ChatFakeDb } from 'app/fake-db/chat';
import { FileManagerFakeDb } from 'app/fake-db/file-manager';
import { ContactsFakeDb } from 'app/fake-db/contacts';

import { ScrumboardFakeDb } from 'app/fake-db/scrumboard';
import { InvoiceFakeDb } from 'app/fake-db/invoice';
import { ProfileFakeDb } from 'app/fake-db/profile';
import { SearchFakeDb } from 'app/fake-db/search';
import { FaqFakeDb } from 'app/fake-db/faq';
import { KnowledgeBaseFakeDb } from 'app/fake-db/knowledge-base';
import { IconsFakeDb } from 'app/fake-db/icons';
import { ChatPanelFakeDb } from 'app/fake-db/chat-panel';
import { QuickPanelFakeDb } from 'app/fake-db/quick-panel';
import { ProjectAgentesDb } from 'app/fake-db/agentes';
import { EtiquetasFakeDb } from 'app/fake-db/etiquetas';
import { CamposFakeDb } from 'app/fake-db/campos';
import { AtajosFakeDb } from 'app/fake-db/atajos';
import { BotsFakeDb } from 'app/fake-db/bot';
import { HorariosFakeDb } from 'app/fake-db/horarios';
import { RolesFakeDb } from 'app/fake-db/roles';
import { ContrysFakeDb } from 'app/fake-db/contry';
import { AgentesAdmFakeDb } from './agentesAdm';
import { LoginSessionFakeDb } from 'app/fake-db/login-session';
import { CanalesFakeDb } from 'app/fake-db/canales';
import { CitasFakeDb } from './citas';
import { TecnicosFakeDB } from './tecnicos';
import { DashboardMensajesFakeDb } from './dashboard-mensajes';
import { LoginSessionService } from "app/services/global/LoginSession.service";
import { Apollo } from 'apollo-angular';
import { HttpClient, HttpHandler } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ProjectDashboardComponent } from 'app/main/apps/dashboards/project/project.component';
import { EventEmitter } from "@angular/core";
import { GetTableV2Service } from 'app/services/global/getTableV2.service';

import { ProjectKamikase } from './dashboard-project2';

import { JwtHelperService } from "@auth0/angular-jwt";


export const Data = {
    limit: 500,
    page: 0
}

@Injectable({
    providedIn: 'root'
})
export class FakeDbService implements InMemoryDbService {
    idUserLogin: string;
    DataDashboard: any[] = [];
    _DataDashboard: any
    NewVa: any;
    _DashboardObservable = new Subject<any>();
    public CountingDashBoard: EventEmitter<any> = new EventEmitter();
    public EmpresasDashboard: EventEmitter<any> = new EventEmitter();
    _LoginSessionService: LoginSessionService
    TokenLogin: string;
    _HttpClient: HttpClient;
    Component: ProjectDashboardComponent
    CallReload: boolean = false;

    constructor() { }

    async createDb(): Promise<any> {
        //this.NewVa = new ProjectDashboardComponent(null,null,null,null,null,null,null,null)
        this._LoginSessionService = new LoginSessionService(null, null, null)
        // this._TotaleDashListening = new TotalesDashboard();
        this.idUserLogin = this._LoginSessionService.GetIdUser()
        this.TokenLogin = this._LoginSessionService.GetToken()

        //this.NewVa.ListernetDashboard();
        return {
            // Dashboards
            'project-dashboard-agentes': ProjectAgentesDb.agentes,
            'Chats-Agentes-Dashboard': ProjectAgentesDb.ChatAgentes,
            'project-dashboard-projects': ProjectDashboardDb.projects,
            'project-dashboard-widgets': ProjectDashboardDb.widgets,
            'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,
            //api con la nueva estructura para dash_control y dash_resumen
            /*             'project-dashboard-control': ProjectDashboardDb.CargarDashboardControl(this.TokenLogin),//ProjectDashboardDb.dashboard_control, */
            'project-dashboard-totalesWidget': ProjectDashboardDb.dashboard_totales,

            //DashboardV2
            'project-dashboardV2': ProjectDashboardDb.widgetsV2,
            'project-dashboardK': ProjectKamikase.TotatesKamikase,


            // Calendar
            'calendar': CalendarFakeDb.CargarCalendar(this.TokenLogin),
            'citas': CitasFakeDb.CargarCitas(this.TokenLogin),

            'canales': CanalesFakeDb.CargaCanales(this.TokenLogin),

            // E-Commerce
            'clientes': ClientesFakeDb.clientes, // .CargasClientes(this.TokenLogin),
            'clientesconfigShow': ClientesFakeDb.configShow,
            'e-commerce-productsorg': ClientesFakeDb.productsorg,
            'e-commerce-orders': ClientesFakeDb.orders,

            // Chat
            'chat-contacts': await ChatFakeDb.CargarContactos(this.TokenLogin),
            'chat-chats': await ChatFakeDb.CargarChats(this.TokenLogin),
            'chat-user': await ChatFakeDb.CargarConversaciones(this.TokenLogin),

            // File Manager
            'file-manager': FileManagerFakeDb.CargarGaleria(this.TokenLogin),

            // Contacts
            'contacts-contacts': ContactsFakeDb.contacts,
            'contactos': ContactsFakeDb.CargarContactos(this.TokenLogin),
            'contactos2': ContactsFakeDb.contactos2,
            'contacts-user': ContactsFakeDb.user,

            'contrys': ContrysFakeDb.Contrys,

            // Tecnicos
            //'tecnicos': TecnicosFakeDB.paramTecnicos,
            'tecnicos': TecnicosFakeDB.cargarTecnicos(this.TokenLogin),
            'tecnicos-vehiculos': TecnicosFakeDB.vehiculos,
            'tecnicos-contratista': TecnicosFakeDB.contratistas,

            // Etiquetas
            'etiquetas': await EtiquetasFakeDb.etiquetas,
            //'atajos': await AtajosFakeDb.CargarAtajos(this.TokenLogin),
            'atajos': await AtajosFakeDb.atajos,
            'horarios': await HorariosFakeDb.CargarHorarios(this.TokenLogin),

            //Campos
            'campos': await CamposFakeDb.campos,    // CargarCampos(this.TokenLogin)
            'bot': await BotsFakeDb.CargarBot(this.TokenLogin),


            // Scrumboard
            'scrumboard-boards': ScrumboardFakeDb.boards,

            // Invoice
            'invoice': InvoiceFakeDb.invoice,

            'login-session': LoginSessionFakeDb.LoginSession,

            // Profile
            'profile-timeline': ProfileFakeDb.timeline,
            'profile-photos-videos': ProfileFakeDb.photosVideos,
            'profile-about': ProfileFakeDb.about,

            // Search
            'search': SearchFakeDb.search,

            // FAQ
            'faq': FaqFakeDb.Faq,

            // Knowledge base
            'knowledge-base': KnowledgeBaseFakeDb.data,

            // Icons
            'icons': IconsFakeDb.icons,

            // Chat Panel
            'chat-panel-contacts': ChatPanelFakeDb.CargarContactos(this.TokenLogin),
            'chat-panel-chats': ChatPanelFakeDb.CargarChats(this.TokenLogin),
            'chat-panel-user': ChatPanelFakeDb.CargarConversaciones(this.TokenLogin),

            // Quick Panel
            'quick-panel-notes': QuickPanelFakeDb.notes,
            'quick-panel-events': QuickPanelFakeDb.events,

            // TypoPago
            'typo-pago': TipoPagoDB.tipopago,
            'typo-pago-prefijo': TipoPagoDB.prefijo,

            //Roles
            'roles': RolesFakeDb.roles,
            'roles-permisos': RolesFakeDb.permisos,
            'roles-users': RolesFakeDb.usersRoles,

            //dashboard-mensajes
            'dashboard-mensajes': DashboardMensajesFakeDb.dashboard_MSG_2,

            //agentes-adm
            'agentes-adm': AgentesAdmFakeDb.CargarAgentes(this.TokenLogin)

        };
    }

    ReloadData(apollo: Apollo, lTokenLogin: string, _HttpClient: HttpClient) {
        const decode = new JwtHelperService()
        let dataUser = decode.decodeToken(lTokenLogin);
        let token: any = localStorage.getItem('login');
        let permission: any;
        let masterUser: any;


        if (!this.CallReload) {
            this.CallReload = true;

            if(dataUser!=null){
                if (dataUser.Data) {

                    permission = dataUser.Data.Permisos;
    
                    masterUser = dataUser.Data.isMaster;
                } else {
                    token = token.replace(/["']/g, "");
                    dataUser = decode.decodeToken(token);
    
                    permission = dataUser.Data.Permisos;
                    masterUser = dataUser.Data.isMaster;
                    lTokenLogin = token;
                }

                localStorage.setItem("ReloadData", "1");

            if (permission.length > 0) {

                //DashboardMensajesFakeDb.MsgDashboard(apollo, lTokenLogin, _HttpClient)
                ChatFakeDb.ConsultaChats(apollo, lTokenLogin, _HttpClient,0,200)
                ChatFakeDb.EsperarRespuesta.subscribe(() => {

                    ClientesFakeDb.GetClientes(apollo, lTokenLogin, _HttpClient);
                    AgentesAdmFakeDb.UserByCliente(apollo, lTokenLogin, _HttpClient);

                    if (permission.includes('1')) {

                        ProjectDashboardDb.ClientesEmpresa(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
                            this.EmpresasDashboard.emit(Datos);
                        })

                        ProjectKamikase.GetTotoalesDashboard(apollo, lTokenLogin, _HttpClient);

                        ProjectAgentesDb.UsersOnline(apollo, lTokenLogin, _HttpClient);

                        ProjectDashboardDb.ConsultaTotales(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
                            this._DataDashboard = Datos
                            //this.DataDashboard = Datos
                            Datos.forEach(element => {
                                this.DataDashboard.push(element)
                            });
                            this.CountingDashBoard.next(this.DataDashboard);
                        }).catch(Error => { console.error(Error) });
                        DashboardMensajesFakeDb.MsgByEmpLic(apollo, lTokenLogin, _HttpClient);
                        // ProjectDashboardDb.ConsultaDRNew(apollo, lTokenLogin, _HttpClient);
                        //ProjectAgentesDb.ChatsUsersD(apollo, lTokenLogin, _HttpClient);
                        //AnalyticsDashboardDb.ConsultaDR(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('2')) {
                        //fue comentado para usar ClientesFakeDb.GetClientes en su lugar
                        //ClientesFakeDb.ReloadClientes(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('3')) {
                        //tengo el permiso para Campaña
                    }
                    if (permission.includes('4')) {
                        //tengo el permiso para Chat
                    }
                    if (permission.includes('5')) {
                        //tengo el permiso para Tareas
                    }
                    if (permission.includes('6')) {
                        //tengo el permiso para Proyectos
                    }
                    if (permission.includes('7')) {
                        //tengo el permiso para Reportes
                    }
                    if (permission.includes('8')) {
                        //tengo el permiso para Agentes
                        //AgentesAdmFakeDb.UserByIdEmpresa(apollo, lTokenLogin, _HttpClient);
                        //AgentesAdmFakeDb.ConsultaAngentes(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('9')) {
                        //tengo el permiso para Contactos
                        ContactsFakeDb.AllContacts(apollo, lTokenLogin, _HttpClient);
                        //ContactsFakeDb.ContactsByCliente(apollo, lTokenLogin, Data, _HttpClient);
                    }
                    if (permission.includes('10')) {
                        //tengo el permiso para Roles
                        RolesFakeDb.CargarRoles(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('11')) {
                        //tengo el permiso para Galería
                        FileManagerFakeDb.ConsultaGaleria(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('12')) {
                        //tengo el permiso para Configuración
                    }
                    if (permission.includes('13')) {
                        //tengo el permiso para Planes y Pagos
                    }
                    if (permission.includes('14')) {
                        //tengo el permiso para API
                    }
                    if (permission.includes('15')) {
                        //tengo el permiso para Preguntas
                        //FaqFakeDb.ConsultaFaq(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('16')) {
                        //tengo el permiso para Tecnicos
                        TecnicosFakeDB.GetTecnicos(apollo, lTokenLogin, _HttpClient);
                    }
                    if (permission.includes('17')) {
                        //tengo el permiso para
                    }
                    if (permission.includes('18')) {
                        //tengo el permiso para Entrenamiento
                    }
                })

            } else {

                console.log("Entre en el else de los permisos")

                ChatFakeDb.ConsultaChats(apollo, lTokenLogin, _HttpClient,0,200)
                ChatFakeDb.EsperarRespuesta.subscribe(() => {

                    ClientesFakeDb.GetClientes(apollo, lTokenLogin, _HttpClient);

                    ProjectDashboardDb.ClientesEmpresa(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
                        this.EmpresasDashboard.emit(Datos);
                    })

                    ProjectKamikase.GetTotoalesDashboard(apollo, lTokenLogin, _HttpClient);

                    ProjectAgentesDb.UsersOnline(apollo, lTokenLogin, _HttpClient);

                    ProjectDashboardDb.ConsultaTotales(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
                        this._DataDashboard = Datos
                        //this.DataDashboard = Datos
                        Datos.forEach(element => {
                            this.DataDashboard.push(element)
                        });

                        this.CountingDashBoard.next(this.DataDashboard);
                    }).catch(Error => { console.error(Error) });

                    DashboardMensajesFakeDb.MsgByEmpLic(apollo, lTokenLogin, _HttpClient);


                    // ProjectDashboardDb.ConsultaDRNew(apollo, lTokenLogin, _HttpClient);

                    //ProjectAgentesDb.ChatsUsersD(apollo, lTokenLogin, _HttpClient);

                    //AnalyticsDashboardDb.ConsultaDR(apollo, lTokenLogin, _HttpClient);

                    //fue comentado para usar ClientesFakeDb.GetClientes en su lugar
                    //ClientesFakeDb.ReloadClientes(apollo, lTokenLogin, _HttpClient);

                    AgentesAdmFakeDb.UserByCliente(apollo, lTokenLogin, _HttpClient);

                    //AgentesAdmFakeDb.UserByIdEmpresa(apollo, lTokenLogin, _HttpClient);

                    //AgentesAdmFakeDb.ConsultaAngentes(apollo, lTokenLogin, _HttpClient);

                    ContactsFakeDb.AllContacts(apollo, lTokenLogin, _HttpClient);

                    //ContactsFakeDb.ContactsByCliente(apollo, lTokenLogin, Data, _HttpClient);

                    RolesFakeDb.CargarRoles(apollo, lTokenLogin, _HttpClient);

                    FileManagerFakeDb.ConsultaGaleria(apollo, lTokenLogin, _HttpClient);

                    //FaqFakeDb.ConsultaFaq(apollo, lTokenLogin, _HttpClient);

                    TecnicosFakeDB.GetTecnicos(apollo, lTokenLogin, _HttpClient);

                })

            }
            // Esto es ya como hacer el New, pero como no se puede hacer sin el contructor
            this._HttpClient = _HttpClient
        }
            
            //TotalesDashboard.GetTotalesDash();

            // this._SubscriptionGraphqlService.setToken(lTokenLogin)
            // this._SubscriptionGraphqlService.initSubscription()
            // Esto debe devolver una promesa.
            // Esta seguro que todo cargo bien.

        }
    }

    // ReloadData(apollo: Apollo, lTokenLogin: string, _HttpClient: HttpClient) {
    //     const decode = new JwtHelperService()
    //     let dataUser = decode.decodeToken(lTokenLogin);
    //     let permission: string[] = [];
    //     let masterUser: boolean;
    //     let token: string = '';
    //     if( dataUser !== undefined  && dataUser) {
    //         permission = dataUser.Data.Permisos;
    //         masterUser = dataUser.Data.isMaster;
    //     } else if ( dataUser === undefined ) {
    //         token = localStorage.getItem('login');
    //         token = token.replace(/["']/g, "");
    //         dataUser = decode.decodeToken(token);
    //         permission = dataUser.Data.Permisos;
    //         masterUser = dataUser.Data.isMaster;
    //         lTokenLogin = token;
    //     }

    //     if (!this.CallReload) {
    //         this.CallReload = true;
    //         //TotalesDashboard.GetTotalesDash();

    //         // this._SubscriptionGraphqlService.setToken(lTokenLogin)
    //         // this._SubscriptionGraphqlService.initSubscription()
    //         // Esto debe devolver una promesa.
    //         // Esta seguro que todo cargo bien.
    //         this.loadInfoMaster(apollo, lTokenLogin, _HttpClient);
    //         localStorage.setItem("ReloadData", "1");
    //         /*
    //         if ( masterUser && dataUser !== undefined && dataUser) {
    //             this.loadInfoMaster(apollo, lTokenLogin, _HttpClient);
    //         } 

    //         if(!masterUser && dataUser !== undefined && dataUser) {
    //             this.loadInfoNormalUser(apollo, token, _HttpClient, permission);
    //         }
    //         */
    //         // Esto es ya como hacer el New, pero como no se puede hacer sin el contructor
    //         this._HttpClient = _HttpClient
    //     }
    // }

    // loadInfoMaster(apollo: Apollo, lTokenLogin: string, _HttpClient: HttpClient) {
    //     ProjectDashboardDb.ClientesEmpresa(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
    //         this.EmpresasDashboard.emit(Datos);
    //     })

    //     ProjectKamikase.GetTotoalesDashboard(apollo, lTokenLogin, _HttpClient);
    //     ChatFakeDb.ConsultaChats(apollo, lTokenLogin, _HttpClient)
    //     ChatFakeDb.EsperarRespuesta.subscribe(() => {
    //         RolesFakeDb.CargarRoles(apollo, lTokenLogin, _HttpClient);
    //         //fue comentado para usar ClientesFakeDb.GetClientes en su lugar
    //         //ClientesFakeDb.ReloadClientes(apollo, lTokenLogin, _HttpClient);
    //         ClientesFakeDb.GetClientes(apollo, lTokenLogin, _HttpClient);
    //         //FaqFakeDb.ConsultaFaq(apollo, lTokenLogin, _HttpClient);
    //         ProjectDashboardDb.ConsultaTotales(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
    //             this._DataDashboard = Datos
    //             //this.DataDashboard = Datos
    //             Datos.forEach(element => {
    //                 this.DataDashboard.push(element)
    //             });

    //             this.CountingDashBoard.next(this.DataDashboard);
    //         }).catch(Error => { console.error(Error) });

    //         // ProjectDashboardDb.ConsultaDRNew(apollo, lTokenLogin, _HttpClient);
    //         ContactsFakeDb.AllContacts(apollo, lTokenLogin, _HttpClient);
    //         //ContactsFakeDb.ContactsByCliente(apollo, lTokenLogin, Data, _HttpClient);
    //         //AgentesAdmFakeDb.UserByIdEmpresa(apollo, lTokenLogin, _HttpClient);
    //         AgentesAdmFakeDb.UserByCliente(apollo, lTokenLogin, _HttpClient);
    //         ProjectAgentesDb.UsersOnline(apollo, lTokenLogin, _HttpClient);
    //         //ProjectAgentesDb.ChatsUsersD(apollo, lTokenLogin, _HttpClient);

    //         //AnalyticsDashboardDb.ConsultaDR(apollo, lTokenLogin, _HttpClient);
    //         //AgentesAdmFakeDb.ConsultaAngentes(apollo, lTokenLogin, _HttpClient);
    //         FileManagerFakeDb.ConsultaGaleria(apollo, lTokenLogin, _HttpClient);
    //         DashboardMensajesFakeDb.MsgByEmpLic(apollo, lTokenLogin, _HttpClient);
    //         TecnicosFakeDB.GetTecnicos(apollo, lTokenLogin, _HttpClient);
    //     });

    // }

    /* Este metodo no funciona para limitar la carga de dtaos incesearios 
    NO FUNCIONA CORRECTAMENTE */
    //     loadInfoNormalUser(apollo: Apollo, lTokenLogin: string, _HttpClient: HttpClient, permission: string[]) {

    //         if ( permission.includes('1') === true ) {
    //             ProjectDashboardDb.ClientesEmpresa(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
    //                 this.EmpresasDashboard.emit(Datos);
    //             })
    //             ProjectKamikase.GetTotoalesDashboard(apollo, lTokenLogin, _HttpClient);
    //             ChatFakeDb.ConsultaChats(apollo, lTokenLogin, _HttpClient)
    //             ChatFakeDb.EsperarRespuesta.subscribe(() => {
    //                 RolesFakeDb.CargarRoles(apollo, lTokenLogin, _HttpClient);
    //                 //fue comentado para usar ClientesFakeDb.GetClientes en su lugar
    //                 //ClientesFakeDb.ReloadClientes(apollo, lTokenLogin, _HttpClient);
    //                 ClientesFakeDb.GetClientes(apollo, lTokenLogin, _HttpClient);
    //                 //FaqFakeDb.ConsultaFaq(apollo, lTokenLogin, _HttpClient);
    //                 ProjectDashboardDb.ConsultaTotales(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
    //                     this._DataDashboard = Datos
    //                     //this.DataDashboard = Datos
    //                     Datos.forEach(element => {
    //                         this.DataDashboard.push(element)
    //                     });

    //                     this.CountingDashBoard.next(this.DataDashboard);
    //                 }).catch(Error => { console.error(Error) });

    //                 // ProjectDashboardDb.ConsultaDRNew(apollo, lTokenLogin, _HttpClient);
    //                 ContactsFakeDb.AllContacts(apollo, lTokenLogin, _HttpClient);
    //                 //ContactsFakeDb.ContactsByCliente(apollo, lTokenLogin, Data, _HttpClient);
    //                 //AgentesAdmFakeDb.UserByIdEmpresa(apollo, lTokenLogin, _HttpClient);
    //                 AgentesAdmFakeDb.UserByCliente(apollo, lTokenLogin, _HttpClient);
    //                 ProjectAgentesDb.UsersOnline(apollo, lTokenLogin, _HttpClient);
    //                 //ProjectAgentesDb.ChatsUsersD(apollo, lTokenLogin, _HttpClient);

    //                 //AnalyticsDashboardDb.ConsultaDR(apollo, lTokenLogin, _HttpClient);
    //                 //AgentesAdmFakeDb.ConsultaAngentes(apollo, lTokenLogin, _HttpClient);
    //                 FileManagerFakeDb.ConsultaGaleria(apollo, lTokenLogin, _HttpClient);
    //                 DashboardMensajesFakeDb.MsgByEmpLic(apollo, lTokenLogin, _HttpClient);
    //                 TecnicosFakeDB.GetTecnicos(apollo, lTokenLogin, _HttpClient);
    //             ProjectKamikase.GetTotoalesDashboard(apollo,lTokenLogin, _HttpClient);
    //         } 
    //     )
    // }
    // /*         if(permission.includes('4') === true) {

    //                 if( permission.includes('10') === true) {
    //                     RolesFakeDb.CargarRoles(apollo, lTokenLogin, _HttpClient);
    //                 } else if( permission.includes('2')=== true ) {
    //                     ClientesFakeDb.GetClientes(apollo, lTokenLogin, _HttpClient);
    //                 } else if ( permission.includes('1') === true) {
    //                     ProjectDashboardDb.ConsultaTotales(apollo, lTokenLogin, _HttpClient).then((Datos: any) => {
    //                         this._DataDashboard = Datos
    //                         //this.DataDashboard = Datos
    //                         Datos.forEach(element => {
    //                             this.DataDashboard.push(element)
    //                         });

    //                         this.CountingDashBoard.next(this.DataDashboard);
    //                     }).catch(Error => { console.error(Error) });
    //                     DashboardMensajesFakeDb.MsgByEmpLic(apollo, lTokenLogin, _HttpClient);
    //                 } else if( permission.includes('9') === true ) {
    //                     ContactsFakeDb.AllContacts(apollo, lTokenLogin, _HttpClient);
    //                 } else if( permission.includes('8')  === true) {
    //                     AgentesAdmFakeDb.UserByCliente(apollo, lTokenLogin, _HttpClient);
    //                     ProjectAgentesDb.UsersOnline(apollo, lTokenLogin, _HttpClient);
    //                 } else if( permission.includes('11') === true ) {
    //                     FileManagerFakeDb.ConsultaGaleria(apollo, lTokenLogin, _HttpClient);
    //                 } else if( permission.includes('16') === true ){ 
    //                     TecnicosFakeDB.GetTecnicos(apollo, lTokenLogin, _HttpClient);
    //                 }

    //             //ChatFakeDb.ConsultaChats(apollo, lTokenLogin, _HttpClient);

    //             /*ChatFakeDb.EsperarRespuesta.subscribe((res) => {

    //             })
    //         } */



    //     }

    getDataDashboard(_HttpClient) {
        return new Promise((resolve, reject) => {
            if (this._DataDashboard != undefined) {
                return this._DataDashboard
            }

            if (this.DataDashboard != undefined && this.DataDashboard.length > 0) {
                resolve(this.DataDashboard)
            } else {
                const _GetTableV2Service = new GetTableV2Service(_HttpClient)
                _GetTableV2Service.TableURL = "project-dashboard-totalesWidget" // "contactos"
                _GetTableV2Service.getData().then(DataIn => {

                    if (DataIn != undefined && DataIn[0].length > 0) {
                        DataIn[0].forEach(element => {
                            element.fecha2 = new Date(element.fecha2);
                        });
                        resolve(DataIn[0]);
                    }

                }).catch((error: any) => reject(error))

            }
        })

    }

    GetTableDashboard(_HttpClient: HttpClient) {
        return new Promise((resolve, reject) => {
            const _GetTableV2Service = new GetTableV2Service(_HttpClient)
            _GetTableV2Service.TableURL = "project-dashboard-totalesWidget" // "contactos"
            _GetTableV2Service.getData().then(DataIn => {

                if (DataIn != undefined && DataIn.length > 0) {
                    if (DataIn[0].length > 0) {
                        DataIn[0].forEach(element => {
                            element.fecha2 = new Date(element.fecha2);
                        });
                        resolve(DataIn[0]);
                    }
                } else {
                    resolve([]);
                }
            }).catch((error: any) => reject(error))
        })
    }

    UpdateRoles(apollo: Apollo, data: any, _HttpClient: HttpClient) {
        RolesFakeDb.UpdateRol(apollo, data, _HttpClient);
    }

    CreateRoles(apollo: Apollo, data: any, _HttpClient: HttpClient) {
        RolesFakeDb.CreateRol(apollo, data, _HttpClient);
    }

    getTotalClientes(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            ClientesFakeDb.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    getTotalAgentes(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            AgentesAdmFakeDb.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    getTotalContactos(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            ContactsFakeDb.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    getTotalChat(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            ChatFakeDb.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    getTotalRol(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            RolesFakeDb.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    getTotalGaleria(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            FileManagerFakeDb.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    getTotalTecnico(): Observable<number> {
        return new Observable((subscriber: Subscriber<number>) => {
            TecnicosFakeDB.Counting.subscribe(data => {
                subscriber.next(data);
            })
        })
    }

    ClearAllAPI() {

        const AllApiService = [
            'project-dashboard-agentes',
            'project-dashboard-projects',
            'project-dashboard-widgets',
            'analytics-dashboard-widgets',
            'project-dashboard-control',
            'project-dashboard-resumen',
            'calendar',
            'citas',
            'canales',
            'clientes',
            'clientesconfigShow',
            'e-commerce-productsorg',
            'e-commerce-orders',
            'chat-contacts',
            'chat-chats',
            'chat-chats-paginados',
            'chat-user',
            'file-manager',
            'contacts-contacts',
            'contactos',
            'contactos2',
            'contacts-user',
            'contrys',
            'tecnicos',
            'tecnicos-vehiculos',
            'tecnicos-contratista',
            'etiquetas',
            'atajos',
            'horarios',
            'campos',    // CargarCampos(this.TokenLogin)
            'bot',
            'scrumboard-boards',
            'invoice',
            'login-session',
            'profile-timeline',
            'profile-photos-videos',
            'profile-about',
            'search',
            'faq',
            'knowledge-base',
            'icons',
            'chat-panel-contacts',
            'chat-panel-chats',
            'chat-panel-user',
            'quick-panel-notes',
            'quick-panel-events',
            'typo-pago',
            'typo-pago-prefijo',
            'roles',
            'roles-permisos',
            'roles-users',
            'dashboard-mensajes',
            'agentes-adm']

        //AllApiService.forEach(element => {
        //this._HttpClient.delete('api/' + element).subscribe(Datos => {
        //})
        //});
    }
}




