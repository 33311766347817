
export const environment = {
  production: true,
  hmr: false,
  uriGraphql: "https://pha.thomas-talk.me/",
  subsGraphql: "wss://pha.thomas-talk.me/subscriptions",
  ServerUploadFile: "https://upload.thomas-talk.me",
  serverImageBase64: 'https://img.thomas-talk.me/api/',
  urlApiSaldo: 'https://api.thomas-talk.me/SaldoCe/',
  urlApiEstadoDeCuenta: 'https://api.thomas-talk.me/GenerateEdoCuenta/',
  urlApiWsp: 'https://wa.thomas-talk.me/',
  urlApi_SSL: 'https://apissl.thomas-talk.me/',
  urlFactura_digital: /* 'https://facturadig.thomas-talk.me/' */  'http://localhost:9007/',
  firebaseConfig: {
    apiKey: "AIzaSyD8u2wPPFAR0V0TyKpHiikfw_ZWnzjKs7Q",
    authDomain: "thomas-v9.firebaseapp.com",
    databaseURL: "https://thomas-v9-default-rtdb.firebaseio.com",
    projectId: "thomas-v9",
    storageBucket: "thomas-v9.appspot.com",
    messagingSenderId: "73649914330",
    appId: "1:73649914330:web:523950baf0b4f2a4de40e3",
    measurementId: "G-CVEBGCGLG0"
  },
  akssl: '4p1_[51t3m4s]_f186@th0m4s',
  methodGeneratePin: 'GenerarPin',
  platform: 'Thomas_v11',
  campanasShow: false,
  citasShow: false,
  tareasShow: false,
  proyectosShow: false,
  reportesShow: true,
  mercadeoShow: false,
  planesShow: false,
  pagosShow: false,
  version: "V11.3.15 - THE FACTORY"
};
